.sidebar {
  height: 100vh;
  /*background-color: #1c1e21;*/
  color: white;
  transition: width 0.3s ease;
  width: 250px;
  position: absolute;  
}

.sidebar.collapsed {
  width: 45px;
}

.sidebar-header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background-color: #20232a;
  border-bottom: 1px solid #444;
  position: relative;
}

.toggle-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  outline: none;
  position: absolute;
  top: 20px;
  background-color: #20232a;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  
  display: flex;
  align-items: center;
  justify-content: center;
  transition: right 0.3s ease;
}

.sidebar .toggle-btn {
  right: -5px;
}

.sidebar.collapsed .toggle-btn {
  right: -25px;
}

.nav-item-custom {
  color: #ddd;
  padding: 10px 15px;
  display: flex;
  align-items: center;
}

.nav-item-custom:hover {
  background-color: #333;
  color: #fff;
}

.fa-icon {
  margin-right: 10px;
  font-size: 16px;
}

.sidebar.collapsed .fa-icon {
  margin-right: 0;
  font-size: 30px;
}

.sidebar.collapsed .ml-2 {
  display: none;
}

.our-brand{
  height: 20px;
  width: 1;
}

.our-brand-collapsed{
  height: 20px;
  width: 1;
}